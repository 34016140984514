// import App from "next/app";
import "../styles/globals.css";

import { GoogleTagManager } from '@next/third-parties/google'
import type { AppProps /*, AppContext */ } from "next/app";
import { Roboto } from "next/font/google";
import { SessionProvider, SessionProviderProps } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import NextNProgress from "nextjs-progressbar";

import siteUrl from "../lib/siteUrl";

const roboto = Roboto({
  weight: ["400", "500", "700"],
  subsets: ["latin", "latin-ext"],
  variable: "--font-roboto",
});

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<SessionProviderProps>) {
  return (
    <SessionProvider session={session}>
      <DefaultSeo
        titleTemplate="%s | Binbircare Sağlık ve Yazılım"
        defaultTitle="Binbircare"
        openGraph={{
          type: "website",
          locale: "tr_TR",
          url: siteUrl,
          site_name: "Binbircare",
          description:
            "Binbircare Sağlık & Yazılım. Rayonex Türkiye Resmi Distribütörü",
          images: [
            {
              url: siteUrl + "/logo-with-background.webp",
              alt: "Binbircare Logo with white background",
            },
            {
              url: siteUrl + "/logo.svg",
              alt: "Binbircare",
            },
          ],
        }}
        twitter={{
          site: "@site",
          cardType: "summary_large_image",
          handle: "@binbircare",
        }}
      />
      <NextNProgress color="#189938" />
      <div className={`${roboto.variable} font-sans`}>
        <Component {...pageProps} />
        <GoogleTagManager gtmId="GTM-NLD6W99" />
      </div>
    </SessionProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
